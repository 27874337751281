import http from  './request';
export const getShowByIds = function (data, callback) {
	$.ajax({
		url: `/ablaze/get/works/ids/${data.point}`,
		type: "get",
		data: data,
		async: true,
		success: function (data) {
			callback(data);
		}
	});
}
export const getShow = function (data, callback) {
	$.ajax({
		url: '/ablaze/get/works/'+data.point,
		type: "get",
		data: data,
		async: true,
		success: function (data) {
			callback(data);
		}
	});
}
export const getComments = function (data, callback){
	$.ajax({
		url : `/ablaze/get/comments/${data.type}/${data.wid}/${data.fid}/${data.start}/${data.num}`,
		type : "get",
		async : true,
		success : function(data) {
			callback(data);

		}
	});
}
export const addComment = function(data, callback){
	$.ajax({
		url : `/ablaze/insert/comment`,
		type : "post",
		headers: {
			Authorization:  window.localStorage.getItem("token")
		},
		data:data,
		async : true,
		success : function(data) {
			callback(data);

		}
	});
}
export const getWork = function(data, callback){
	$.ajax({
		url : `/ablaze/get/work/${data.point}/${data.id}`,
		type : "get",
		data:data,
		async : true,
		success : function(data) {
			callback(data);
		}
	});
}
/**
 * 
 * @param {*} data 
 * @param {*} callback 
 */
export const actWork = function(data, callback){
	$.ajax({
		url : `/ablaze/act/work/${data.point}/${data.wid}/${data.act}`,
		type : "post",
		headers: {
			Authorization:  window.localStorage.getItem("token")
		},
		async : true,
		success : function(data) {
			callback(data);

		}
	});
	
}
/**
 * 
 * @param {point,start,num,keys} data 
 * @param {*} callback 
 */
export const searchWork = function(data, callback){
	$.ajax({
		url : `/ablaze/search/works/${data.point}`,
		type : "get",
		data:data,
		async : true,
		success : function(data) {
			callback(data);

		}
	});
}
export const getHotKeys = function(data, callback){
	$.ajax({
		url : `/ablaze/get/keywords/${data.start}/${data.num}/${data.key}`,
		type : "get",
		async : true,
		success : function(data) {
			callback(data);

		}
	});
}
export const getUser = function(data,callback){
	$.ajax({
		url:`/ablaze/get/user/${data.id}`,
		type:"get",
		async: true,
		success: function (data) {
			callback(data);
		}
	})
}
export const setUserPassword = function(data,callback){
	$.ajax({
		url : `/ablaze/set/userpassword`,
		type : "post",
		headers: {
			Authorization:  window.localStorage.getItem("token")
		},
		data:data,
		async : true,
		success : function(data) {
			callback(data);
		}
	});
}
export const updateUser = function(data,callback){
	$.ajax({
		url : `/ablaze/set/user`,
		type : "post",
		headers: {
			Authorization:  window.localStorage.getItem("token")
		},
		data:data,
		async : true,
		success : function(data) {
			callback(data);
		}
	});
}
export const updateUserSafe = function(data,callback){
	$.ajax({
		url : `/ablaze/set/safe/user`,
		type : "post",
		headers: {
			Authorization:  window.localStorage.getItem("token")
		},
		data:data,
		async : true,
		success : function(data) {
			callback(data);
		}
	});
}
export const followUser = function(data,callback){
	$.ajax({
		url:`/ablaze/act/user/${data.tid}/${data.act}`,
		type:"post",
		headers: {
			Authorization:  window.localStorage.getItem("token")
		},
		async: true,
		success: function (data) {
			callback(data);
		}
	})
}
export let bangu_getCollection = function(data,callback){
	let arr = [];
	for(var p in data){
		if(data.hasOwnProperty(p)){
			arr.push(encodeURIComponent(p)+"="+encodeURIComponent(data[p]))
		}
	};
	$.ajax({
		url:`/api/scrape?url=${encodeURIComponent("https://api.bgm.tv/v0/users/607071/collections?"+arr.join("&"))}`,
		type:"get",
		dataType:'json',
		async: true,
		success: function (data) {
			callback(data);
		}
	})
}
export let bangu_getCollections = function(data,callback){
	$.ajax({
		url:`/ablaze/gethtml?url=https://api.bgm.tv/user/607071/collections/${data.type}?app_id=bgm191760e15e1785468`,
		type:"get",
		async: true,
		dataType:'json',
		success: function (data) {
			callback(data);
		}
	})
}

export let getAlistContent = function (data) {
	return http.get(`/alist/fs/list`,data)
}

export let getValue = function (data) {
	return http.get(`/kv/key-value/query`,data)
}

export let setValue = function (data) {
	return http.post(`/kv/key-value`,data)
}

/**
 * 创建一个订单
 *
 * @param {Object} data - JSON对象，包含以下字段：
 *  - productDescription {string} - 商品描述
 *  - amount {number} - 价格
 *  - userId {string} - 用户标识
 *  - productCode {string} - 产品标识
 * @returns {Promise} - HTTP POST请求的Promise对象
 */
export let createOrder = function(data) {
	return http.post(`/trade/order/createOrder`,data)
}
/**
 * 查询订单状态
 *
 * @param {data}  
 *  - transactionId {string} 订单ID
 * @returns {Promise} - HTTP POST请求的Promise对象
 */
export let queryOrderStatus = function(data) {
	return http.get(`/trade/order/createOrder`,data)
}